import React from 'react'
import styled from 'styled-components'

const squares = [
  {
    width: 66,
    height: 66,
    color: '#FFE99F',
    borderRadius: 6,
    position: {
      left: '79px',
      top: '170px'
    },
    animationName: 'orbit-right'
  },
  {
    width: 13,
    height: 13,
    color: '#6367FD',
    borderRadius: 1,
    position: {
      left: '40%',
      top: '105px'
    },
    animationName: 'orbit-right'
  },
  {
    width: 32,
    height: 32,
    color: '#FFF6D8',
    borderRadius: 3,
    position: {
      left: '50%',
      top: '25%'
    }
  },
  // {
  //   width: 317,
  //   height: 317,
  //   color: '#B6B9FF',
  //   borderRadius: 28,
  //   position: {
  //     left: '80%',
  //     top: '30%'
  //   },
  //   animationName: 'orbit-right'
  // },
  {
    width: 152,
    height: 152,
    color: '#EAF3FF',
    borderRadius: 13,
    position: {
      left: '60%',
      top: '30%'
    },
    animationName: 'orbit-left'
  },
  {
    width: 25,
    height: 25,
    color: '#EAEDFF',
    borderRadius: 2,
    position: {
      left: '79px',
      top: '75%'
    },
    animationName: 'orbit-right'
  },
  {
    width: 79,
    height: 79,
    color: '#FFE9EB',
    borderRadius: 7,
    position: {
      left: '30%',
      top: '100%'
    }
  }
]

const howItWorksSquares = [
  {
    width: 25,
    height: 25,
    color: '#FFE99F',
    borderRadius: 2,
    position: {
      left: '10%',
      top: '15%'
    }
  },
  {
    width: 20,
    height: 20,
    color: '#EAEDFF',
    borderRadius: 2,
    position: {
      left: '80%',
      top: '90%'
    }
  }
]

const learningModeSection = [
  {
    width: 25,
    height: 25,
    color: '#EAF3FF',
    borderRadius: 2,
    position: {
      left: '20%',
      top: '20%'
    },
    animationName: 'orbit-left'
  },
  {
    width: 13,
    height: 13,
    color: '#B6B9FF',
    borderRadius: 2,
    position: {
      left: '40%',
      top: '0%'
    },
    animationName: 'orbit-left'
  },
  // {
  //   width: 218,
  //   height: 218,
  //   color: '#FFE99F',
  //   borderRadius: 19,
  //   position: {
  //     left: '75%',
  //     top: '40%'
  //   },
  //   animationName: 'orbit-left'
  // },
]


const learnWhatYouNeedSection = [
  {
    width: 179,
    height: 179,
    color: '#B6B9FF',
    borderRadius: 15,
    position: {
      left: '9%',
      top: '40%'
    },
    animationName: 'up-and-down'
  },
  {
    width: 20,
    height: 20,
    color: '#F4B6BD',
    borderRadius: 2,
    position: {
      left: '55%',
      top: '80%'
    },
    animationName: 'orbit-right'
  },
]

const purpleSectionSquares = [
  // {
  //   width: 248,
  //   height: 248,
  //   color: '#B6B9FF',
  //   borderRadius: 21,
  //   position: {
  //     left: '75%',
  //     top: '45%'
  //   },
  //   animationName: 'orbit-right'
  // },
  {
    width: 61,
    height: 61,
    color: '#F4B6BD',
    borderRadius: 5,
    position: {
      left: '15%',
      top: '20%'
    },
    animationName: 'orbit-right'
  },
  {
    width: 17,
    height: 17,
    color: '#6367FD',
    borderRadius: 1,
    position: {
      left: '35%',
      top: '10%'
    },
    animationName: 'orbit-left'
  }
]

const contactSquares = [
  {
    width: 20,
    height: 20,
    color: '#6367FD',
    borderRadius: 2,
    position: {
      left: '15%',
      top: '20%'
    },
    animationName: 'orbit-left'
  },
  {
    width: 61,
    height: 61,
    color: '#EAF3FF',
    borderRadius: 5,
    position: {
      left: '80%',
      top: '80%'
    },
    animationName: 'orbit-left'
  },
  {
    width: 20,
    height: 20,
    color: '#FFE99F',
    borderRadius: 2,
    position: {
      left: '90%',
      top: '60%'
    },
    animationName: 'orbit-left'
  },
  {
    width: 23,
    height: 23,
    color: '#FFE9EB',
    borderRadius: 2,
    position: {
      left: '70%',
      top: '5%'
    },
    animationName: 'orbit-left'
  }

]

export const AnimationRight = styled.div`
    animation-name: orbit-right;
    animation-duration: 25s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: normal;
`

export const AnimationLeft = styled.div`
    animation-name: orbit-left;
    animation-duration: 25s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: normal;
`

export const AnimatedDiv = styled(AnimationRight)`
    height: 134px;
    width: 134px;
    background-color: #FFE9EB;
    border-radius: 6px;
    position: absolute;
    top: 50%;
    left: 20%;
`

export const AnimatedDivYellow = styled(AnimationLeft)`
    height: 128px;
    width: 128px;
    background-color: #FFE99F;
    border-radius: 11px;
    position: absolute;
    top: 10%;
    left: 70%;
`

export const AnimatedDivSmall = styled(AnimationLeft)`
    height: 11px;
    width: 11px;
    background-color: #6367FD;
    border-radius: 1px;
    position: absolute;
    top: 35%;
    left: 40%;
`
export const AnimatedDivSmallTwo = styled(AnimationRight)`
    height: 27px;
    width: 27px;
    background-color: #EAEDFF;
    border-radius: 1px;
    position: absolute;
    top: 30%;
    left: 50%;
`

export const AllAnimationsCombined = (props) => {
  if (props.contact) {
    return (
      <div className="is-hidden-touch">
        {contactSquares.map((item, index) => {
          return (
            <AnimationLeft
              key={`${index}`}
              style={{
                height: item.height,
                width: item.width,
                backgroundColor: item.color,
                borderRadius: item.borderRadius,
                position: 'absolute',
                left: item.position.left,
                top: item.position.top,
                animationName: item.animationName
              }}
            />
          )
        })}
      </div>
    )
  }
  if (props.purpleSection) {
    return (
      <div className="is-hidden-touch">
        {purpleSectionSquares.map((item, index) => {
          return (
            <AnimationLeft
              key={`${index}`}
              style={{
                height: item.height,
                width: item.width,
                backgroundColor: item.color,
                borderRadius: item.borderRadius,
                position: 'absolute',
                left: item.position.left,
                top: item.position.top,
                animationName: item.animationName
              }}
            />
          )
        })}
      </div>
    )
  }
  if (props.learnWhatYouNeed) {
    return (
      <div className="is-hidden-touch">
        {learnWhatYouNeedSection.map((item, index) => {
          return (
            <AnimationLeft
              key={`${index}`}
              style={{
                height: item.height,
                width: item.width,
                backgroundColor: item.color,
                borderRadius: item.borderRadius,
                position: 'absolute',
                left: item.position.left,
                top: item.position.top,
                animationName: item.animationName
              }}
            />
          )
        })}
      </div>
    )
  }
  if (props.learningModeSection) {
    return (
      <div className="is-hidden-touch">
        {learningModeSection.map((item, index) => {
          return (
            <AnimationLeft
              key={`${index}`}
              style={{
                height: item.height,
                width: item.width,
                backgroundColor: item.color,
                borderRadius: item.borderRadius,
                position: 'absolute',
                left: item.position.left,
                top: item.position.top,
                animationName: item.animationName
              }}
            />
          )
        })}
      </div>
    )
  }
  if (props.howItWorks) {
    return (
      <div className="is-hidden-touch">
        {howItWorksSquares.map((item, index) => {
          return (
            <AnimationLeft
              key={`${index}`}
              style={{
                height: item.height,
                width: item.width,
                backgroundColor: item.color,
                borderRadius: item.borderRadius,
                position: 'absolute',
                left: item.position.left,
                top: item.position.top,
                animationName: item.animationName
              }}
            />
          )
        })}
      </div>
    )
  }

  let squaresToDisplay = props.singlePage ? squares.slice(0, 5) : squares

  return (
    <div className="is-hidden-touch">
      {squaresToDisplay.map((item, index) => {
        return (
          <AnimationLeft
            key={`${index}`}
            style={{
              height: item.height,
              width: item.width,
              backgroundColor: item.color,
              borderRadius: item.borderRadius,
              position: 'absolute',
              left: item.position.left,
              top: item.position.top,
              animationName: item.animationName
            }}
          />
        )
      })}
    </div>
  )
}