const Images = {
  getStartedImage: require('./get_started.png'),
  logoYellowWhite: require('./underline_yellow_white_logo.png'),
  logoYellowWhite2x: require('./underline_yellow_white_logo@2x.png'),
  logoYellowWhite3x: require('./underline_yellow_white_logo@3x.png'),
  mockupPhone: require('./mockup_phone.png'),
  mockupPhone2x: require('./mockup_phone@2x.png'),
  mockupPhone3x: require('./mockup_phone@3x.png'),
  mockupPhoneLanding: require('./mockup_phone_landing.png'),
  mockupPhoneLanding2x: require('./mockup_phone_landing@2x.png'),
  mockupPhoneLanding3x: require('./mockup_phone_landing@3x.png'),
  hierarchy: require('./hierarchy-4.png'),
  hierarchy2x: require('./hierarchy-4@2x.png'),
  hierarchy3x: require('./hierarchy-4@3x.png'),
  studyBrain: require('./study-brain-1.png'),
  studyBrain2x: require('./study-brain-1@2x.png'),
  studyBrain3x: require('./study-brain-1@3x.png'),
  analyticsBoard: require('./analytics-board-graph-line.png'),
  analyticsBoard2x: require('./analytics-board-graph-line@2x.png'),
  analyticsBoard3x: require('./analytics-board-graph-line@3x.png'),
  case: require('./case.png'),
  case2: require('./case2.png'),
  macbook: require('./Macbook.png'),
  macbook2x: require('./Macbook@2x.png'),
  macbook3x: require('./Macbook@3x.png'),
  macAppStore: require('./mac_app_store.png'),
  macAppStore2x: require('./mac_app_store@2x.png'),
  macAppStore3x: require('./mac_app_store@3x.png'),
  googlePlay: require('./google_play.png'),
  googlePlay2x: require('./google_play@2x.png'),
  googlePlay3x: require('./google_play@3x.png'),
  shadows: require('./shadows.png'),
  shadows2x: require('./shadows@2x.png'),
  shadows3x: require('./shadows@3x.png'),
  homeBlue: require('./home_blue.png'),
  homeBlue2x: require('./home_blue@2x.png'),
  homeBlue3x: require('./home_blue@3x.png'),
  homeProgress: require('./home_progress.png'),
  homeProgress2x: require('./home_progress@2x.png'),
  homeProgress3x: require('./home_progress@3x.png'),
  thankYou: require('./thank-you.png'),
  thankYou2x: require('./thank-you@2x.png'),
  thankYou3x: require('./thank-you@3x.png'),
  LinkedIn: require('./svgs/linkedin.inline.svg'),
  PersonAvatar: require('./svgs/person_avatar.inline.svg'),
  Twitter: require('./svgs/twitter.inline.svg'),
  Instagram: require('./svgs/instagram.inline.svg'),
  TikTok: require('./svgs/tiktok.inline.svg'),
  Facebook: require('./svgs/facebook.inline.svg'),
  FacebookLogo: require('./svgs/facebook_logo.inline.svg'),
  GoogleLogo: require('./svgs/google_logo.inline.svg'),
  AppleLogo: require('./svgs/apple_logo.inline.svg'),
  CheckedInline: require('./svgs/checked.inline.svg'),
  ArrowLeft: require('./svgs/arrow_left.inline.svg'),
  IconBlueChecked: require('./svgs/icon_checked_blue.inline.svg'),
  IconWhiteChecked: require('./svgs/icon_checked_white.inline.svg'),
  IconCheckedGrayedOut: require('./svgs/icon_checked_grayed_out.inline.svg'),
  HowTheExtensionWorks: require('./HowTheExtensionWorks.png'),
  HowTheExtensionWorks2x: require('./HowTheExtensionWorks@2x.png'),
  HowTheExtensionWorks3x: require('./HowTheExtensionWorks@3x.png'),
  mockupPhoneNew: require('./mockupPhoneNew.png'),
  mockupPhoneNew2x: require('./mockupPhoneNew@2x.png'),
  mockupPhoneNew3x: require('./mockupPhoneNew@3x.png'),
  IntelligentAnalize: require('./IntelligentAnalize.png'),
  IntelligentAnalize2x: require('./IntelligentAnalize@2x.png'),
  IntelligentAnalize3x: require('./IntelligentAnalize@3x.png'),
  TranslatorInExtension: require('./TranslatorInExtension.png'),
  TranslatorInExtension2x: require('./TranslatorInExtension@2x.png'),
  TranslatorInExtension3x: require('./TranslatorInExtension@3x.png'),
  MobileAppLearning: require('./MobileAppLearning.png'),
  MobileAppLearning2x: require('./MobileAppLearning@2x.png'),
  MobileAppLearning3x: require('./MobileAppLearning@3x.png'),
  notFound: require('./404.png'),
  notFound2x: require('./404@2x.png'),
  notFound3x: require('./404@3x.png'),
  chrome: require('./chrome.png'),
  chrome2x: require('./chrome@2x.png'),
  chrome3x: require('./chrome@3x.png'),
  hugeThingLogo: require('./huge_thing_logo.png'),
  hugeThingLogo2x: require('./huge_thing_logo@2x.png'),
  hugeThingLogo3x: require('./huge_thing_logo@3x.png'),
  logoGoogleForStartups: require('./logo_google_for_startups.png'),
  logoGoogleForStartups2x: require('./logo_google_for_startups@2x.png'),
  logoGoogleForStartups3x: require('./logo_google_for_startups@3x.png'),
  operaLogo: require('./opera_logo.png'),
  operaLogo2x: require('./opera_logo@2x.png'),
  operaLogo3x: require('./opera_logo@3x.png'),
  microsoftEdge: require('./microsoft_edge_logo.png'),
  microsoftEdge2x: require('./microsoft_edge_logo@2x.png'),
  microsoftEdge3x: require('./microsoft_edge_logo@3x.png'),
}

export default Images